import React from 'react';
import CheckoutInfoPage from 'rev.sdk.js/Templates/CheckoutInfoPage';
import withPageEntry from '../../withPageEntry';
import * as CustomCheckout from '../../Utils/CustomCheckout';

function CheckoutInfo(props) {
  return (
    <CheckoutInfoPage {...props} {...CustomCheckout.getExtraInfoPageProps()} />
  );
}

export default withPageEntry(CheckoutInfo);
