import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {req} from 'rev.sdk.js/Utils/ApiUtil';
import {getOutlet, useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import data from '../../../data.json';

export default function LogisticsTrackingButton(props) {
  const [user] = useOutlet('user');
  const {order} = props;
  const [modalVisible, setModalVisible] = React.useState(false);

  if (order.logistics_subtype !== 'SF') {
    return null;
  }

  return (
    <>
      <Ant.Button type="primary" ghost onClick={() => setModalVisible(true)}>
        追蹤物流狀態
      </Ant.Button>

      <LogisticsTrackingModal
        visible={modalVisible}
        setVisible={setModalVisible}
        user={user}
        {...props}
      />
    </>
  );
}

function LogisticsTrackingModal(props) {
  const {visible, setVisible} = props;

  return (
    <Ant.Modal
      destroyOnClose={true}
      visible={visible}
      onCancel={() => setVisible(false)}
      title={null}
      footer={null}>
      <ModalContent {...props} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {order, user} = props;
  const [resp, setResp] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setResp(
          await req(
            `${data.apiHost}/logistics/tracking/status?token=${user.token}&order_id=${order.order_id}`,
          ),
        );
      } catch (ex) {
        console.warn('LogisticsTrackingButton.useEffect() ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [order, user]);

  return (
    <Wrapper>
      <h2>物流狀態</h2>
      {resp && (
        <div className="string-type">
          <label>最新訊息</label>
          <div>{resp.logistics_message}</div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .string-type {
    background-color: #eee;
    border-radius: 10px;
    padding: 20px;

    & > label {
      display: block;
      color: #888;
    }
  }
`;
