import React from 'react';
import {Input} from 'antd';
import styled from 'styled-components';

export default function OverseaLogisticsInfo(props) {
  const {logistics_subtype, getExtraData, setExtraData, disabled} = props;
  const [values, setValues] = React.useState({
    ...(getExtraData() || {}),
  });

  const countries = {
    CN: '大陸',
    MO: '澳門',
    HK: '香港',
  };

  const getInputProps = (field, {placeholder = ''} = {}) => {
    return {
      disabled,
      placeholder,
      defaultValue: values[field] || '',
      value: values[field],
      onChange: (e) => {
        const nextValues = {...values, [field]: e.target.value};
        setValues(nextValues);
        setExtraData(nextValues);
      },
    };
  };
  return (
    <OverseaFormWrapper>
      <h3>海外寄送資訊({countries[logistics_subtype] || logistics_subtype})</h3>

      {!disabled && (
        <div style={{color: '#aaa', margin: '10px 0'}}>
          * 以下欄位皆為必填, 請務必留下正確資訊, 以利我們後續作業!
        </div>
      )}

      <div className="oversea-fields">
        <div>
          <label style={{display: 'block', marginBottom: 5}}>收件人</label>
          <Input {...getInputProps('receiver_name')} />
        </div>
        <div>
          <label style={{display: 'block', marginBottom: 5}}>收件人手機</label>
          <Input {...getInputProps('receiver_phone')} />
        </div>
        <div>
          <label style={{display: 'block', marginBottom: 5}}>收件人信箱</label>
          <Input {...getInputProps('receiver_email')} />
        </div>
        <div>
          <label style={{display: 'block', marginBottom: 5}}>郵遞區號</label>
          <Input {...getInputProps('receiver_post_code')} />
        </div>
        <div>
          <label style={{display: 'block', marginBottom: 5}}>一級行政區</label>
          <Input {...getInputProps('receiver_region_first')} />
        </div>
        <div>
          <label style={{display: 'block', marginBottom: 5}}>二級行政區</label>
          <Input {...getInputProps('receiver_region_second')} />
        </div>
        <div>
          <label style={{display: 'block', marginBottom: 5}}>三級行政區</label>
          <Input {...getInputProps('receiver_region_third')} />
        </div>
        <div>
          <label style={{display: 'block', marginBottom: 5}}>地址</label>
          <Input.TextArea {...getInputProps('receiver_address')} />
        </div>
      </div>
    </OverseaFormWrapper>
  );
}

const OverseaFormWrapper = styled.div`
  & > .oversea-fields {
    & > * {
      margin-bottom: 15px;
    }
  }
`;
